import React from 'react';
import queryString from 'query-string';

import SuccessMessage from 'core/ui/components/SuccessMessage';

const SuccessPayment = () => {
  const parsed = queryString.parse(window.location.search);

  return (
    <SuccessMessage data={parsed} />
  );
};

export default SuccessPayment;
