import styled, { css } from 'styled-components';

// import { getBorderColor } from 'core/ui/helpers';

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.7em 1.5em;
  line-height: 1.7;
  background: transparent;
  font-weight: 300;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 50px;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  color: ${p => p.theme.colors.white};
  :read-only {
    border-color: ${p => p.theme.colors.grays.light};
    color: ${p => p.theme.colors.grays.regular};
  }
  :focus-visible {
    outline: 0;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${p => p.theme.colors.grays.light};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${p => p.theme.colors.grays.light};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${p => p.theme.colors.grays.light};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${p => p.theme.colors.grays.light};
  }
  ${p =>
    p.error &&
    css`
      border-color: ${p => p.theme.colors.danger};
    `};
`;

export default Input;
