import React from 'react';
import styled from 'styled-components';

import { logout } from 'core/api/actions';
import logoutImage from '../../assets/images/logout.svg';

const LogoutButton = () => {
  const logOut = () => {
    logout();
  };

  return (
    <ButtonCont onClick={logOut}>
      <Icon src={logoutImage} alt='log out' />
    </ButtonCont>
  );
}

const ButtonCont = styled.div`
  position: fixed;
  right: 40px;
  bottom: 70px;
  width: 72px;
  height: 72px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: rgba(255,255,255,0.4);
  }
  ${p => p.theme.max('sm')`
    width: 64px;
    height: 64px;
    right: 20px;
    bottom: 40px;
	`}
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  ${p => p.theme.max('sm')`
    width: 24px;
    height: 24px;
	`}
`;

export default LogoutButton;
