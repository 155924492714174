import React, { useState } from "react"
import styled from "styled-components"

import Review from "./Review"
import ANGLE_LEFT_ICON from "core/assets/images/angle_left.png"
import ANGLE_RIGHT_ICON from "core/assets/images/angle_right.png"
import shuffle from 'lodash/shuffle';

const REVIEWS = [
  {
    author: "Advantages.name1",
    text: "Advantages.text1",
  },
  {
    author: "Advantages.name2",
    text: "Advantages.text2",
  },
  {
    author: "Advantages.name3",
    text: "Advantages.text3",
  },
  {
    author: "Advantages.name4",
    text: "Advantages.text4",
  },
  {
    author: "Advantages.name5",
    text: "Advantages.text5",
  },
  {
    author: "Advantages.name6",
    text: "Advantages.text6",
  },
  {
    author: "Advantages.name7",
    text: "Advantages.text7",
  },
  {
    author: "Advantages.reviewFirstAuthor",
    text: "Advantages.reviewFirstText",
  },
  {
    author: "Advantages.reviewSecondAuthor",
    text: "Advantages.reviewSecondText",
  },
  {
    author: "Reviews.name1",
    text: "Reviews.text1",
  },
  {
    author: "Reviews.name2",
    text: "Reviews.text2",
  },
  {
    author: "Reviews.name3",
    text: "Reviews.text3",
  },
  {
    author: "Reviews.name4",
    text: "Reviews.text4",
  },
  {
    author: "Reviews.name5",
    text: "Reviews.text5",
  },
  {
    author: "Reviews.name6",
    text: "Reviews.text6",
  },
  {
    author: "Reviews.name7",
    text: "Reviews.text7",
  },
];

const SHUFFLED_REVIEWS = shuffle(REVIEWS);

const Reviews = () => {
  const [current, setCurrent] = useState(0)

  const next = () => {
    if (current < REVIEWS.length - 1) {
      setCurrent(current + 1)
    } else {
      setCurrent(0)
    }
  }

  const prev = () => {
    setCurrent(current === 0 ? REVIEWS.length - 1 : current - 1)
  }

  return (
    <Cont>
      <SliderCont>
        {SHUFFLED_REVIEWS.map((r, i) => (
          <Review review={r} key={`review-${i}`} isVisible={current === i} />
        ))}
      </SliderCont>
      <Arrow onClick={prev}>
        <Icon src={ANGLE_LEFT_ICON} />
      </Arrow>
      <Arrow right onClick={next}>
        <Icon src={ANGLE_RIGHT_ICON} />
      </Arrow>
    </Cont>
  )
}

const Cont = styled.div`
  position: relative;
  margin-bottom: 33px;
  white-space: pre-wrap;
`

const Arrow = styled.button`
  position: absolute;
  top: 66px;
  background-color: rgba(0, 0, 0, 0.13);
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.23);
  }
  ${(p) => (p.right ? "right: 33px;" : "left: 33px;")}
  ${(p) => p.theme.max("sm")`
    width: 26px;  
    height: 26px;
    ${p.right ? "right: -12px;" : "left: -12px;"}
    &:before {
      font-size: 14px;
    }
  `}
`

const SliderCont = styled.div`
  position: relative;
  height: 300px;
  ${(p) => p.theme.max("md")`
    height: 230px;
  `}
  ${(p) => p.theme.max("sm")`
    height: 480px;
  `}
`

const Icon = styled.div`
  width: 19px;
  height: 19px;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  ${(p) => p.theme.max("sm")`
    width: 14px;
    height: 14px;
  `}
`

export default Reviews
