import React from 'react';
import styled, { css } from 'styled-components';
import { Flex, Text } from './index';

const TypeSwitch = ({
                      values = [],
                      onSelect,
                      selectedValue,
                    }) => {
  return (
    <Flex justifyContent={'center'} alignItems={'center'}>
      {values.map((v, i) => (
        <ItemCont
          key={v.value}
          isLast={i === values.length - 1}
          onClick={() => onSelect(v.value)}
        >
          <ItemText
            isActive={selectedValue === v.value}
            white
          >
            {v.title}
          </ItemText>
        </ItemCont>
      ))}
    </Flex>
  );
};

const ItemCont = styled(Flex).attrs({
  alignItems: 'center',
})`
  padding-left: 35px;
  padding-right: 35px;
  height: 26px;
  border-right: 1px ${p => p.theme.colors.white} solid;
  cursor: pointer;
  ${p => p.isLast && css`border-right: none`};
`;

const ItemText = styled(Text)`
  opacity: 0.5;
  ${p => p.isActive && css`opacity: 1;`}
`;

export default TypeSwitch;