// Layouts
import PublicLayout from 'core/ui/Layouts/PublicLayout';
// import AuthenticatedLayout from 'core/ui/Layouts/AuthenticatedLayout';

// Pages
import SignIn from 'core/ui/pages/SignIn';
import SatiPayment from 'core/ui/pages/SatiPayment';
import SuccessPayment from 'core/ui/pages/SuccessPayment';
// import SatiDirectPayment from 'core/ui/pages/SatiDirectPayment';
import CertificatePage from '../ui/pages/Certificate';

export default [
  {
    component: SignIn,
    layout: PublicLayout,
    isPublic: true,
    redirectLogged: true,
    path: '/sign-in'
  },
  {
    component: SuccessPayment,
    layout: PublicLayout,
    isPublic: true,
    path: '/success'
  },
  // {
  //   component: SatiDirectPayment,
  //   layout: PublicLayout,
  //   isPublic: true,
  //   path: '/direct'
  // },
  {
    component: CertificatePage,
    layout: PublicLayout,
    isPublic: true,
    path: '/certificate'
  },
  {
    component: SatiPayment,
    layout: PublicLayout,
    isPublic: false,
    path: '/'
  },
];
