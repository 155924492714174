import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import new_bg from 'core/assets/images/new_bg.png';
import new_bg_small from 'core/assets/images/new_bg_small.png';
import old_bg from 'core/assets/images/old_bg.png';
import old_bg_small from 'core/assets/images/old_bg_small.png';

const BgContainer = ({ children }) => {
  const location = useLocation();
  const isNewBg = location && location.pathname === '/certificate';

  return (
    <Container isNewBg={isNewBg}>
      {children}
    </Container>
  );
};

const Container = styled.div`
	flex: 1;
	min-height: 420px;
	padding: 24px;
	background-image: url(${p => p.isNewBg ? new_bg : old_bg});
	background-size: cover;
	${p => p.theme.max('sm')`
    background-image: url(${p => p.isNewBg ? new_bg_small : old_bg_small});
	`}
`;

export default BgContainer;
