import React from 'react';
import PropTypes from 'prop-types';

const InAppNotificationsContext = React.createContext();

export const InAppNotificationsConsumer = InAppNotificationsContext.Consumer;

export class InAppNotificationProvider extends React.Component {

  state = { notifications: [] };

  showNotification = ({
    title: notificationTitle,
    message: notificationMessage,
    type: notificationType,
    confirm,
    confirmText,
    skip,
    skipText,
    duration,
  }) => {
    const newNotification = {
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      confirm,
      skip,
      confirmText,
      skipText,
      duration,
      _id: `notification-${Math.random()}`
    };

    this.setState(state => {
      const updatedNotifications = [...state.notifications, newNotification];
      return { notifications: updatedNotifications };
    });

    if (!confirm && !skip) {
      setTimeout(() => this.setState({
        notifications: this.state.notifications.filter(m => m !== newNotification)
      }), duration || 4000);
    }
  };

  clearNotifications = () => {
    this.setState({ notifications: [] });
  };

  render() {
    return (
      <InAppNotificationsContext.Provider
        value={{
          ...this.state,
          showNotification: this.showNotification,
          clearNotifications: this.clearNotifications
        }}>
        {this.props.children}
      </InAppNotificationsContext.Provider>
    );
  }
}

InAppNotificationProvider.propTypes = {
  children: PropTypes.object
};
