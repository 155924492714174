import React, { useState } from "react";
import styled, { css } from "styled-components";
import { withTranslation } from "react-i18next";
import { Head } from 'react-static';

import {
  Checkbox,
  Button,
  Input as InputAtom,
  SectionWrap,
  Logo,
  Text,
  Select,
} from "../atoms";
import CertificateInstruction from '../components/CertificateInstruction';
import Reviews from '../components/Reviews';
// import Background from '../components/Background';
import { API_HOST } from "../../api/constants";
// import NY from 'core/ui/components/NY';

const DISCOUNTS = false;

const CertificatePage = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("subscription1year");
  const [emailChecked, setEmailChecked] = useState(false);
  const [sbp, setSbp] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const toggleEmailChecked = () => {
    setEmailChecked((st) => !st);
  };

  const togglePolicy = () => {
    setPolicyChecked((st) => !st);
  };

  const init = async (isSBP) => {
    if (loading) {
      return;
    }
    const product = OPTIONS.find((o) => o.value === selectedProduct);
    if (!product) {
      return;
    }
    try {
      setLoading(true);
      const url = isSBP
        ? `${API_HOST}/payment/initSingleNoUserSBP`
        : `${API_HOST}/payment/initSingleNoUser`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          product: product.product,
          productName: product.title,
          amount: product.amount,
          days: product.days,
        }),
      });
      const content = await response.json();
      const paymentUrl = content?.paymentUrl;
      setLoading(false);
      if (paymentUrl) {
        window.location.href = paymentUrl;
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const initRegular = async () => {
    setSbp(false);
    await init();
  };

  const initSBP = async () => {
    setSbp(true);
    await init(true);
  };

  const OPTIONS = DISCOUNTS ? [
    {
      id: 3,
      title: 'На год за 1074 ₽',
      titlePart1: 'На год за ',
      titlePart2: ' 1074 ₽',
      oldAmount: 1790,
      amount: 1074,
      product: "subscription1year",
      days: 360,
      value: "subscription1year",
      descTitle: t("Tiers.certificateDescTitle3"),
      descText: t("Tiers.certificateDescText3"),
      descText1: t("Tiers.certificateDescText3-1"),
      descText2: t("Tiers.certificateDescText3-2"),
    },
    {
      id: 4,
      title: 'На всю жизнь за 3359 ₽',
      titlePart1: 'На всю жизнь за ',
      titlePart2: ' 3359 ₽',
      oldAmount: 4799,
      amount: 3359,
      product: "subscriptionforever",
      days: 9999,
      value: "subscriptionforever",
      descTitle: t("Tiers.certificateDescTitle4"),
      descText: t("Tiers.certificateDescText4"),
      descText1: t("Tiers.certificateDescText4-1"),
      descText2: t("Tiers.certificateDescText4-2"),
    },
  ] : [
    {
      id: 3,
      title: 'На год за 1790 ₽',
      titlePart1: 'На год за ',
      titlePart2: ' 1790 ₽',
      oldAmount: 1790,
      // amount: 1490,
      amount: 1790,
      product: "subscription1year",
      days: 360,
      value: "subscription1year",
      descTitle: t("Tiers.certificateDescTitle3"),
      descText: t("Tiers.certificateDescText3"),
      descText1: t("Tiers.certificateDescText3-1"),
      descText2: t("Tiers.certificateDescText3-2"),
    },
    {
      id: 4,
      title: 'На всю жизнь за 4799 ₽',
      titlePart1: 'На всю жизнь за ',
      titlePart2: ' 4799 ₽',
      oldAmount: 4799,
      // amount: 3990,
      amount: 4799,
      product: "subscriptionforever",
      days: 9999,
      value: "subscriptionforever",
      descTitle: t("Tiers.certificateDescTitle4"),
      descText: t("Tiers.certificateDescText4"),
      descText1: t("Tiers.certificateDescText4-1"),
      descText2: t("Tiers.certificateDescText4-2"),
    },
  ];

  const product = OPTIONS.find(o => o.value === selectedProduct) || {};

  const buttonsDisabled = !email || !emailChecked || !policyChecked;

  return (
    <>
    {/* <Background /> */}
    <div>
      <Head>
        <title>{t('html.titleCertificate')}</title>
        <meta name="description" content={t('html.descriptionCertificate')} />
      </Head>
      <Logo />
      <Pagewrap>
        <PagePart isLeft>
          <Wrap>
            {product.id === 3 ? (
              <>
                <ProductHeading>{product.descTitle}</ProductHeading>
                <ProductDescription>{product.descText1}</ProductDescription>
                <ProductDescription>{product.descText2}</ProductDescription>
              </>
            ) : null}
            {product.id === 4 ? (
              <>
                <ProductHeading>{product.descTitle}</ProductHeading>
                <ProductLineCont>
                  <CheckBox />
                  <ProductDescription noMargin alignLeft>{product.descText1}</ProductDescription>
                </ProductLineCont>
                <ProductLineCont>
                  <CheckBox />
                  <ProductDescription noMargin alignLeft>{product.descText2}</ProductDescription>
                </ProductLineCont>
              </>
            ) : null}
          </Wrap>
          <Line />
        </PagePart>
        <PagePart isRight>
          <PageHeading>
            {t("main.certificateTitle1")}
            <br />
            {t("main.certificateTitle2")}
          </PageHeading>
          <Wrap>
            <Input value={email} onChange={onEmailChange} placeholder={"Email"} />
            <Checkbox checked={emailChecked} onChecked={toggleEmailChecked}>
              <div>
                <PolicyText white>{t("main.certificateEmailDisclaimer")}</PolicyText>
              </div>
            </Checkbox>
            <Select
              name={"Product"}
              options={OPTIONS}
              onChange={setSelectedProduct}
              isDiscount={DISCOUNTS}
            />
            <CheckWrap>
              <Checkbox checked={policyChecked} onChecked={togglePolicy}>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <PolicyText white>{t('main.policyTextAgreement')}</PolicyText>
                  <PolicyLink href='https://help.cielomeditation.ru' target='_blank'>{t('main.purchaseAgreementText2')}</PolicyLink>
                </div>
              </Checkbox>
            </CheckWrap>
          </Wrap>
          <SectionWrap>
            <ButtonContainer>
              <ButtonsWrap>
                <Button
                  onClick={initRegular}
                  isLoading={loading && !sbp}
                  disabled={buttonsDisabled || !policyChecked}
                >
                  {t("main.proceed")}
                </Button>
                <Button
                  onClick={initSBP}
                  isLoading={loading && sbp}
                  disabled={buttonsDisabled || !policyChecked}
                >
                  {t("main.proceedSBP")}
                </Button>
              </ButtonsWrap>
              <PurchaseButtonDisclaimer white>
                {t("main.purchaseCertificateDisclaimer")}
              </PurchaseButtonDisclaimer>
            </ButtonContainer>
          </SectionWrap>
        </PagePart>
      </Pagewrap>
      <CertificateInstruction />
      <Reviews />
      {/* <NY /> */}
    </div>
    </>
  );
};

const PageHeading = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  color: ${(p) => p.theme.colors.white};
  text-align: center;
  ${(p) => p.theme.max("sm")`
		font-size: 22px;
		line-height: 28px;
		text-align: center;
	`}
`;

const PolicyText = styled(Text)`
  color: ${(p) => p.white ? 'white' : p.theme.colors.grays.light};
  margin: 0;
  display: inline;
  font-size: 16px;
  margin-right: 4px;
`;

const PolicyLink = styled.a`
  color: ${(p) => p.theme.colors.white};
  text-decoration: none;
  ${(p) => p.theme.max("sm")`
    white-space: break-spaces;
    text-align: center;
	`}
`;

const Wrap = styled(SectionWrap)`
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-wrap;
`;

const CheckWrap = styled.div`
  padding-top: 12px;
`;

const Input = styled(InputAtom)`
  margin-bottom: 12px;
  ${p => p.theme.max('sm')`
    font-size: 18px;
    padding: 0.6em 1.2em;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsWrap = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  > button:first-child {
    margin-bottom: 12px;
  }
  ${(p) => p.theme.max("sm")`
		flex-direction: column;
		> button:first-child {
			margin-right: 0;
			margin-bottom: 12px;
		}
	`}
`;

const PurchaseButtonDisclaimer = styled(Text)`
  color: ${(p) => p.white ? 'white' : p.theme.colors.grays.regular};
  text-align: center;
  white-space: pre-wrap;
  ${p => p.theme.max('sm')`
    font-size: 14px;
  `}
`;

const Pagewrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  ${(p) => p.theme.max("md")`
    flex-direction: column;
  `}
`;

const PagePart = styled.div`
  position: relative;
  width: 50%;
  ${p => p.isLeft && css`padding-right: 40px;`}
  ${p => p.isRight && css`padding-left: 40px;`}
  ${(p) => p.theme.max("md")`
    width: 100%;
    ${p => p.isLeft && css`padding-right: 0;`}
    ${p => p.isRight && css`padding-left: 0;`}
  `}
`;

const ProductHeading = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  color: ${(p) => p.theme.colors.white};
  text-align: left;
  ${(p) => p.theme.max("md")`
    text-align: center;
  `}
  ${(p) => p.theme.max("sm")`
		font-size: 22px;
		line-height: 28px;
		text-align: center;
	`}
`;

const ProductDescription = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.white};
  ${p => p.noMargin && css`margin: 0;`};
  ${(p) => p.theme.max("md")`
    text-align: center;
  `}
  ${(p) => p.theme.max("sm")`
		font-size: 16px;
		line-height: 18px;
		text-align: ${p => p.alignLeft ? 'left' : 'center'}
	`}
`;

const ProductLineCont = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const CheckBox = () => {
  return (
    <svg
      fill="#FFFFFF"
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      style={{
        marginRight: 8,
        flexShrink: 0,
      }}
    >
      <path d="M 5 3 C 3.897 3 3 3.897 3 5 L 3 19 C 3 20.103 3.897 21 5 21 L 19 21 C 20.103 21 21 20.103 21 19 L 21 9.2421875 L 19 11.242188 L 19.001953 19 L 5 19 L 5 5 L 16.757812 5 L 18.757812 3 L 5 3 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z" />
    </svg>
  );
};

const Line = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  width: 1px;
  height: 380px;
  background: rgba(255, 255, 255, 0.5);
  ${p => p.theme.max('sm')`
    display: none;
  `}
`;

export default withTranslation()(CertificatePage);
