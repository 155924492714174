import client from './apollo/init';
import { SIGN_IN } from './apollo/mutations';
import history from './history';

export const loginWithPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    client.mutate({ mutation: SIGN_IN, variables: { email, password, isAdmin: false } })
      .then(({ data: { signIn } }) => {
        if (signIn) {
          localStorage.setItem('token--LMA', signIn.token);
          return client.resetStore();
        }
        reject('error');
      })
      .then(() => {
        history.push('/');
      })
      .catch(e => {
        reject(e);
      });
  });
};

export const loginWithToken = async token => {
  localStorage.setItem('token--LMA', token);
  setTimeout(async () => {
    await client.resetStore();
    history.push('/');
  }, 2000);
};
export const logout = () => {
  localStorage.removeItem('token--LMA');
  history.push('/');
  return client.resetStore();
};
