import gql from 'graphql-tag';

export const loggedUserData = gql`
  fragment LoggedUserData on User {
    _id
    createdAt
    personalData {
      avatar
      email
      firstName
      gender
      timezone
    }
    serviceData {
      currentDay
      locale
      meditationStatus
      totalTime
      maxStreak
      paymentSkipped
      currentStreak
      defaultDuration
      defaultAtmosphere
      lastActiveCourse
      extendedPractice
      dayPassedData
      isSubscribed
      expirationDate
      expirationDateWeb
      forceSubscribed
      recurrentPayment
      webDiscount
      notifications {
        send
        token
      }
    }
  }
`;

export const promocodeData = gql`
  fragment PromocodeData on Promocode {
    code
    type
    notSelf
    notWithActive
    activated
    expirationDate
    expirationDateFull
    ownerId
    userId
    days
    product
    isActive
  }
`;