import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { withTranslation } from "react-i18next";
import moment from "moment";

import Tiers from "../components/Tiers/Tiers";
import LogoutButton from "../components/LogoutButton";
import Promocode from '../components/Promocode';
import PromocodesList from '../components/PromocodesList';
import PromocodeInfoModal from '../components/PromocodeInfoModal';
import { Button, SectionWrap, Logo, Text, Confirm } from "../atoms";
import { withAccount } from "core/api/accounts/accountContext";
import { CURRENT_USER } from "core/api/apollo/queries";
import {
  INIT_PAYMENT,
  INIT_PAYMENT_SBP,
  CANCEL_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION,
} from "core/api/apollo/mutations";
// import BLACK_FRIDAY_IMAGE from '../../assets/images/black_friday.png';
// import NY from 'core/ui/components/NY';

const SatiPayment = ({ t, currentUser }) => {
  const [tier, setTier] = useState();
  const [loading, setLoading] = useState(false);
	const [sbp, setSbp] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [showCancelSubConfirm, setShowCancelSubConfirm] = useState(false);
  const [showClearSubConfirm, setShowClearSubConfirm] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false);
  const [isPromocodeModalVisible, setIsPromocodeModalVisible] = useState(false);

  const [initPayment] = useMutation(INIT_PAYMENT);
  const [initPaymentSBP] = useMutation(INIT_PAYMENT_SBP);
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);
  const [clearSubscription] = useMutation(CLEAR_SUBSCRIPTION);

  const expirationDateWeb = currentUser?.serviceData?.expirationDateWeb;
  const forceSubscribed = currentUser?.serviceData?.forceSubscribed;
  const subscriptionUntil = moment(Math.max(expirationDateWeb, forceSubscribed));
  const recurrentPayment = currentUser?.serviceData?.recurrentPayment;
  const isExpired = Math.max(expirationDateWeb, forceSubscribed) < Date.now();

  const toggleCancelSub = () => {
    setShowCancelSubConfirm((st) => !st);
  };

  const toggleClearSub = () => {
    setShowClearSubConfirm((st) => !st);
  };

  const togglePolicy = () => {
    setPolicyChecked((st) => !st);
  };

  const togglePromocodeModal = () => {
    setIsPromocodeModalVisible(!isPromocodeModalVisible);
  }

  const init = async (isSBP) => {
		if (loading) {
			return;
		}
    const mutate = isSBP ? initPaymentSBP : initPayment;
    setLoading(true);
    try {
      const response = await mutate({
        variables: {
          input: {
            product: tier.product,
            productName: tier.title,
            amount: tier.amount,
            days: tier.days,
          },
        },
      });
      setLoading(false);
      const paymentUrl = isSBP
        ? response?.data?.initPaymentSBP?.paymentUrl
        : response?.data?.initPayment?.paymentUrl;
      if (paymentUrl) {
        window.location.href = paymentUrl;
      }
    } catch (e) {
      // console.log(e);
      setLoading(false);
    }
  };

  const initRegular = async () => {
		setSbp(false);
    await init();
  };

  const initSBP = async () => {
		setSbp(true);
    await init(true);
  };

  const cancel = async () => {
    if (unsubscribing) {
      return;
    }
    setUnsubscribing(true);
    try {
      await cancelSubscription({
        refetchQueries: [{ query: CURRENT_USER }],
      });
      setUnsubscribing(false);
      setShowCancelSubConfirm(false);
    } catch (e) {
      setUnsubscribing(false);
    }
  };

  const clear = async () => {
    if (unsubscribing) {
      return;
    }
    setClearing(true);
    try {
      await clearSubscription({
        refetchQueries: [{ query: CURRENT_USER }],
      });
      setClearing(false);
      setShowClearSubConfirm(false);
    } catch (e) {
      setClearing(false);
    }
  };

  const isForever = subscriptionUntil > moment('2050-01-01');

  return (
    <div>
      <Logo />
      {expirationDateWeb || forceSubscribed ? (
        <div>
          {/* <Promocode
            togglePromocodeModal={togglePromocodeModal}
          /> */}
          <PageHeading>{t("main.successPaymentHeading")}</PageHeading>
          <PageSubHeading>
            {currentUser?.personalData?.firstName}
            {", "}
            {currentUser?.personalData?.email}
          </PageSubHeading>
          <PageSubHeading special>
            {isExpired ? t("main.paymentUntilText2") : t("main.paymentUntilText")}
            {!isForever ? (
              <b>{subscriptionUntil.format("DD.MM.YYYY")}</b>
            ) : (
              <InfinitySign>∞</InfinitySign>
            )}
          </PageSubHeading>
          {recurrentPayment ? (
            <PageSubHeading small>{t("main.chargeMessage1")}</PageSubHeading>
          ) : (
            <PageSubHeading small>{t("main.chargeMessage2")}</PageSubHeading>
          )}
          <CancelButtonContainer>
            <a href="mailto:support@sati.app">
              <Button fullWidth>{t("main.supportButtonText")}</Button>
            </a>
            {recurrentPayment ? (
              <Button fullWidth onClick={toggleCancelSub} isRed>
                {t("main.cancelSubscriptionButtonText")}
              </Button>
            ) : (
              <Button fullWidth onClick={toggleClearSub} isRed>
                {t("main.clearSubscriptionButtonText")}
              </Button>
            )}
          </CancelButtonContainer>
          <PromocodesList togglePromocodeModal={togglePromocodeModal} />
        </div>
      ) : (
        <div>
          <Promocode
            togglePromocodeModal={togglePromocodeModal}
          />
          <PageHeading>
            {t("main.greetings")}, {currentUser?.personalData?.firstName}!
          </PageHeading>
          <UserEmail>{currentUser?.personalData?.email}</UserEmail>
          <PageSubHeading>{t("main.chooseSubscriptionText")}</PageSubHeading>
          <SectionWrap>
            <Tiers
              currentTier={tier}
              setTier={setTier}
              policyChecked={policyChecked}
              togglePolicy={togglePolicy}
              currentUser={currentUser}
            />
          </SectionWrap>
          <SectionWrap>
            <ButtonContainer>
              <ButtonsWrap>
                <Button
                  onClick={initRegular}
                  isLoading={loading && !sbp}
                  disabled={!tier || !policyChecked}
                >
                  {t("main.proceed")}
                </Button>
                <Button
                  onClick={initSBP}
                  isLoading={loading && sbp}
                  disabled={!tier || !policyChecked}
                >
                  {t("main.proceedSBP")}
                </Button>
              </ButtonsWrap>
              <PurchaseButtonDisclaimer>
                {t("main.purchaseButtonDisclaimer")}
              </PurchaseButtonDisclaimer>
            </ButtonContainer>
          </SectionWrap>
        </div>
      )}
      {/* <Bottom /> */}
      <LogoutButton />
      {showCancelSubConfirm ? (
        <Confirm
          title={t("main.confirmTitle")}
          text={t("main.confirmText")}
          cancelText={t("main.confirmCancelText")}
          confirmText={t("main.confirmConfirmText")}
          onConfirm={cancel}
          confirmPending={unsubscribing}
          onCancel={toggleCancelSub}
        />
      ) : null}
      {showClearSubConfirm ? (
        <Confirm
          title={t("main.confirmTitle2")}
          text={t("main.confirmText2")}
          cancelText={t("main.cancel")}
          confirmText={t("main.confirm")}
          onConfirm={clear}
          confirmPending={clearing}
          onCancel={toggleClearSub}
        />
      ) : null}
      <PromocodeInfoModal
        isVisible={isPromocodeModalVisible}
        onClose={togglePromocodeModal}
      />
      {/* <NY /> */}
    </div>
  );
};

const PageHeading = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  color: ${(p) => p.theme.colors.white};
  text-align: center;
  ${(p) => p.theme.max("sm")`
		font-size: 22px;
		line-height: 28px;
		text-align: center;
	`}
`;

const PageSubHeading = styled(Text)`
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: ${(p) => p.theme.colors.white};
  text-align: center;
  margin: 0;
  ${p => p.special && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
  ${(p) =>
    p.small &&
    css`
      font-size: 20px;
    `}
  ${(p) => p.theme.max("sm")`
		font-size: 20px;
		line-height: 26px;
		text-align: center;
		${(p) =>
      p.small &&
      css`
        font-size: 16px;
      `}
	`}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsWrap = styled.div`
  justify-content: center;
  display: flex;
  > button:first-child {
    margin-right: 12px;
  }
	${p => p.theme.max('sm')`
		flex-direction: column;
		> button:first-child {
			margin-right: 0;
			margin-bottom: 12px;
		}
	`}
`;

const CancelButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  flex-direction: column;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  button {
    margin-bottom: 30px;
  }
`;

const PurchaseButtonDisclaimer = styled(Text)`
  color: ${(p) => p.theme.colors.grays.regular};
  text-align: center;
`;

// const BlackFriday = styled.a`
//   position: fixed;
//   top: 0;
//   left: 0;
//   cursor: pointer;
// `;

// const BlackFridayImage = styled.img`
//   width: 200px;
//   height: 200px;
//   z-index: 9999;
//   position: relative;
// `;

const UserEmail = styled.p`
  color: white;
  text-align: center;
  font-size: 20px;
  margin-top: -22px;
  ${p => p.theme.max('sm')`
    font-size: 18px;
    margin-top: -18px;
	`}
`;

const InfinitySign = styled.b`
  font-size: 48px;
  margin-left: 8px;
  ${p => p.theme.max('sm')`
    font-size: 42px;
	`}
`;

export default withTranslation()(withAccount(SatiPayment));
