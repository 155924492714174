import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { lighten } from 'polished';

import { withTheme } from 'core/api/themeContext';
import { Flex, Text, SvgIcon } from 'core/ui/atoms';

const ToastContent = ({ notification, isDark }) => {
  const {
    message,
    title,
    type,
    confirm,
    confirmText,
    skip,
    skipText,
  } = notification;

  const [showNotification, setShowNotification] = useState(true);

  const hideNotification = () => {
    setShowNotification(false);
  };

  const confirmAction = () => {
    hideNotification();
    confirm();
  };

  const skipAction = () => {
    hideNotification();
    skip();
  };

  return (
    <Context show={showNotification}>
      <ToastWrap>
        <Heading type={type} isDark={isDark}>
          {title}
        </Heading>
        <Message>
          {message}
        </Message>
        <ButtonCont>
          {confirm && <ConfirmButton isDark={isDark} success onClick={confirmAction}>{confirmText || 'Да'}</ConfirmButton>}
          {skip && <DismissButton isDark={isDark} onClick={skipAction}>{skipText || 'Нет'}</DismissButton>}
        </ButtonCont>
      </ToastWrap>
      <CloseIconCont isDark={isDark}>
        <CloseNotification onClick={hideNotification} />
      </CloseIconCont>
    </Context>
  );
};

ToastContent.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    confirm: PropTypes.func,
    confirmText: PropTypes.string,
    skip: PropTypes.func,
    skipText: PropTypes.string,
    isDark: PropTypes.bool,
  }),
  isDark: PropTypes.bool,
};

const animationToastRight = keyframes`
 0% { transform: translateX(100%); }
 30% { transform: translateX(-10%); }
 70% { transform: translateX(5%); }
 100% { transform: translateX(0); }
`;

const Context = withTheme(styled(Flex)`
  background: ${p => p.isDark ? p.theme.colors.black : 'white'};
  box-shadow: 0 4px 30px rgba(142, 148, 165, 0.15);
  transition: transform .6s ease-in-out;
	animation: ${animationToastRight} 1s;
	border-radius: 16px;
	margin-bottom: 8px;
	position: relative;
	display: ${p => p.show ? 'flex' : 'none'};
  width: 384px;
  padding: 20px;
  /* background: rgba(255,255,255,0.01); */
  box-shadow: 2px 4px 20px rgb(0 0 0 / 15%);
`);

const ToastWrap = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
`;

const Heading = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: ${p => p.theme.colors.black};
  margin: 0;
  margin-bottom: 6px;
  padding: 0;
`;

const Message = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${p => p.theme.colors.grays.regular};
  margin: 0 0 10px 0;
  padding: 0;
  white-space: pre-wrap;
`;

const CloseNotification = styled(SvgIcon.CloseCross)``;

const CloseIconCont = styled(Flex)`
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  border-radius: 28px;
  backdrop-filter: blur(20px);
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${p => p.theme.colors.grays.light};
  cursor: pointer;
  &:hover {
    color: ${p => p.isDark ? p.theme.colors.white : p.theme.colors.grays.light};
  }
`;

const ButtonCont = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ConfirmButton = styled.button`
  background: ${p => p.isDark ? '#17171B' : p.theme.colors.grays.lighter};
  border: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 8px;
  margin: 0 8px 0 0;
  color: ${p => p.theme.colors.premium};
  cursor: pointer;
  border-radius: 24px;
  &:hover {
    background: ${p => p.isDark ? '#17171B' : lighten(0.25, p.theme.colors.grays.light)};
  }
`;

const DismissButton = styled(ConfirmButton)`
  background: ${p => p.isDark ? 'none' : p.theme.colors.grays.lighter};
  color: ${p => p.isDark ? p.theme.colors.white : p.theme.colors.grays.light};
  &:hover {
    background: ${p => p.isDark ? '#17171B' : lighten(0.25, p.theme.colors.grays.light)};
  }
`;

export default withTheme(ToastContent);
