import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  fill: currentColor;
`;

export const SvgContainer = ({ className, width, height, children, ...rest }) => (
  <SVG
    className={className}
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {children}
  </SVG>
);

const Play = props => (
  <SvgContainer width={28} height={28} {...props}>
    <path d={'M11.3334 20.0003V8.00033L19.3334 14.0003L11.3334 20.0003ZM14 0.666992C12.2491 0.666992 10.5152 1.01187 8.89757 1.68193C7.2799 2.35199 5.81004 3.33412 4.57193 4.57224C2.07144 7.07272 0.666687 10.4641 0.666687 14.0003C0.666687 17.5365 2.07144 20.9279 4.57193 23.4284C5.81004 24.6665 7.2799 25.6487 8.89757 26.3187C10.5152 26.9888 12.2491 27.3337 14 27.3337C17.5362 27.3337 20.9276 25.9289 23.4281 23.4284C25.9286 20.9279 27.3334 17.5365 27.3334 14.0003C27.3334 12.2494 26.9885 10.5156 26.3184 8.89788C25.6484 7.2802 24.6662 5.81035 23.4281 4.57224C22.19 3.33412 20.7201 2.35199 19.1025 1.68193C17.4848 1.01187 15.751 0.666992 14 0.666992Z'} />
  </SvgContainer>
);

const Pause = props => (
  <SvgContainer width={28} height={28} {...props}>
    <path d={'M18 19.3337H15.3334V8.66699H18V19.3337ZM12.6667 19.3337H10V8.66699H12.6667V19.3337ZM14 0.666992C12.2491 0.666992 10.5152 1.01187 8.89757 1.68193C7.2799 2.35199 5.81004 3.33412 4.57193 4.57224C2.07144 7.07272 0.666687 10.4641 0.666687 14.0003C0.666687 17.5365 2.07144 20.9279 4.57193 23.4284C5.81004 24.6665 7.2799 25.6487 8.89757 26.3187C10.5152 26.9888 12.2491 27.3337 14 27.3337C17.5362 27.3337 20.9276 25.9289 23.4281 23.4284C25.9286 20.9279 27.3334 17.5365 27.3334 14.0003C27.3334 12.2494 26.9885 10.5156 26.3184 8.89788C25.6484 7.2802 24.6662 5.81035 23.4281 4.57224C22.19 3.33412 20.7201 2.35199 19.1025 1.68193C17.4848 1.01187 15.751 0.666992 14 0.666992Z'} />
  </SvgContainer>
);

const Close = props => (
  <SvgContainer width={14} height={14} {...props}>
    <path d={'M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'} />
  </SvgContainer>
);

const CloseCross = props => (
  <SvgContainer width={14} height={14} {...props}>
    <path d={'M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'} />
  </SvgContainer>
);

const Check = props => (
  <SvgContainer width={20} height={17} {...props} >
    <path d={'M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z'} />
  </SvgContainer>
);

const ErrorExclamation = props => (
  <SvgContainer width={22} height={19} {...props} >
    <path d={'M12 12H10V7H12V12ZM12 16H10V14H12V16ZM0 19H22L11 0L0 19Z'} />
  </SvgContainer>
);

const NotifyQuestion = props => (
  <SvgContainer width={16} height={24} {...props}>
    <path d={'M7.67253 24C8.65435 24 9.3089 23.3333 9.3089 22.3333C9.3089 21.3333 8.65435 20.6667 7.67253 20.6667C6.69071 20.6667 6.03617 21.3333 6.03617 22.3333C6.03617 23.3333 6.69071 24 7.67253 24ZM6.52708 18H8.81799V17.3333C8.81799 11.8333 15.1998 12.6667 15.1998 6.33333C15.1998 3 12.2544 0 7.9998 0C2.1089 0 0.799805 5.33333 0.799805 5.33333L2.92708 6C2.92708 6 3.74526 2.33333 7.9998 2.33333C10.9453 2.33333 12.7453 4.16667 12.7453 6.33333C12.7453 10.8333 6.36344 11.1667 6.36344 16.5C6.36344 17.2667 6.52708 18 6.52708 18Z'} />
  </SvgContainer>
);

const IconRing = props => (
  <SvgContainer width={22} height={21} {...props}>
    <path d={'M20 17V18H2V17L4 15V9C4 5.9 6.03 3.17 9 2.29C9 2.19 9 2.1 9 2C9 1.46957 9.21071 0.960859 9.58579 0.585786C9.96086 0.210714 10.4696 0 11 0C11.5304 0 12.0391 0.210714 12.4142 0.585786C12.7893 0.960859 13 1.46957 13 2C13 2.1 13 2.19 13 2.29C15.97 3.17 18 5.9 18 9V15L20 17ZM13 19C13 19.5304 12.7893 20.0391 12.4142 20.4142C12.0391 20.7893 11.5304 21 11 21C10.4696 21 9.96086 20.7893 9.58579 20.4142C9.21071 20.0391 9 19.5304 9 19H13ZM18.75 1.19L17.33 2.61C19.04 4.3 20 6.6 20 9H22C22 6.07 20.84 3.25 18.75 1.19ZM0 9H2C2 6.6 2.96 4.3 4.67 2.61L3.25 1.19C1.16 3.25 0 6.07 0 9Z'} />
  </SvgContainer>
);

const Copy = props => (
  <SvgContainer width={22} height={22} {...props}>
      <path
        d={'M 4 2 C 2.895 2 2 2.895 2 4 L 2 17 C 2 17.552 2.448 18 3 18 C 3.552 18 4 17.552 4 17 L 4 4 L 17 4 C 17.552 4 18 3.552 18 3 C 18 2.448 17.552 2 17 2 L 4 2 z M 8 6 C 6.895 6 6 6.895 6 8 L 6 20 C 6 21.105 6.895 22 8 22 L 20 22 C 21.105 22 22 21.105 22 20 L 22 8 C 22 6.895 21.105 6 20 6 L 8 6 z M 8 8 L 20 8 L 20 20 L 8 20 L 8 8 z'}
      />
  </SvgContainer>
);

export default {
  Play,
  Pause,
  Close,
  CloseCross,
  Check,
  ErrorExclamation,
  NotifyQuestion,
  IconRing,
  Copy
};
