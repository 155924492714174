import React from 'react';
import { useQuery } from "@apollo/react-hooks";
import styled from 'styled-components';

import { SvgIcon } from 'core/ui/atoms';
import { USER_PROMOCODES } from 'core/api/apollo/queries';
import { withInAppNotifications } from 'core/api/toast/withInAppNotifications';
import moment from 'moment';

const PromocodesListItem = ({ promocode, index, showNotification }) => {
  const copyPromocode = () => {
    navigator.clipboard.writeText(promocode.code);
    showNotification({
      title: promocode.code,
      message: 'Промокод скопирован',
      type:'INFO',
    });
  };

  const isExpired = promocode.expirationDate && promocode.expirationDate < Date.now();
  const statusText = promocode.activated ? 'Использован' : isExpired ? 'Истек срок действия' : 'Активен';

  return (
    <PromocodeItemText activated={promocode.activated}>
      {index + 1}{' '}{promocode.code}
      <CopyIcon onClick={copyPromocode} />
      {'на '}{promocode.days}{' дней'}
      {promocode.expirationDate ? `, действует до ${moment(promocode.expirationDate).format('YYYY.MM.DD')}` : ''}
      {', статус: '}{statusText}
    </PromocodeItemText>
  );
};

const PromocodesList = ({ showNotification, togglePromocodeModal }) => {
  const { data } = useQuery(USER_PROMOCODES);
  const promocodes = data?.userPromocodes || [];

  if (!promocodes || !promocodes.length) {
    return null;
  }

  return (
    <Wrap>
      <HeadingWrap>
        <Heading>Ваши промокоды</Heading>
        <MoreButton onClick={togglePromocodeModal}>(как использовать?)</MoreButton>
      </HeadingWrap>
      {promocodes.map((p, i) => (
        <PromocodesListItem
          key={p.code}
          promocode={p}
          index={i}
          showNotification={showNotification}
        />
      ))}
    </Wrap>
  );
};

const Heading = styled.h3`
  color: white;
  margin: 0;
`;

const Wrap = styled.div`
  max-width: 820px;
  margin-right: auto;
  margin-left: auto;
`;

const PromocodeItemText = styled.p`
  color: white;
  opacity: ${p => p.activated ? 0.3 : 1};
  white-space: normal;
`;

const CopyIcon = styled(SvgIcon.Copy)`
  cursor: pointer;
  margin-left: 4px;
  margin-right: 8px;
  :hover {
    opacity: 0.5;
  }
`;

const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 18px 0;
`;

const MoreButton = styled.span`
  color: white;
  cursor: pointer;
  margin-left: 4px;
  :hover {
    opacity: 0.3;
  }
`;

export default withInAppNotifications(PromocodesList);
