import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconAtom from './Icon';

import ARROW_ICON from '../../assets/images/icons8-expand-arrow-32.png';

class Select extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    isDiscount: PropTypes.bool
  };

  state = {
    selected: this.props.options[0] && this.props.options[0].value,
  };

  componentDidMount() {
    const { selected } = this.props;
    if (selected !== undefined) {
      this.setState({ selected });
      this.props.onChange && this.props.onChange(selected);
    }
  }

  onChange = e => {
    this.setState({ selected: e.target.value });
    this.props.onChange && this.props.onChange(e.target.value);
  };

  render() {
    const { name, options, readOnly, isDiscount } = this.props;
    const { selected } = this.state;
    const selectedOption = options.find(o => o.value === selected) || {};

    return (
      <SelectCont>
        {isDiscount ? (
          <Selected className={this.props.className} readOnly={readOnly}>
            {selectedOption.titlePart1}
            <strike>{selectedOption.oldAmount}</strike>
            {selectedOption.titlePart2}
            {/* <Icon icon="cared_down" /> */}
          </Selected>
        ) : (
          <Selected className={this.props.className} readOnly={readOnly}>
            {selectedOption.title}
            <Icon icon="cared_down" />
          </Selected>
        )}
      
        <ArrowIcon src={ARROW_ICON} />
        <Input name={name} onChange={this.onChange} value={selected} disabled={readOnly}>
          {options.map(({ value, title }, i) => (
            <option key={`${value}-${i}`} value={value}>
              {title}
            </option>
          ))}
        </Input>
      </SelectCont>
    );
  }
}

const SelectCont = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 4px 32px;
  line-height: 1.7;
  background: transparent;
  font-weight: 300;
  background: rgba(255,255,255,0.01);
  border: 1px solid rgba(255,255,255,0.5);
  box-shadow: 2px 4px 20px rgb(0 0 0 / 15%);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 50px;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
  ${(p) => p.theme.max("sm")`
    font-size: 18px;
    padding: 4px 16px;
  `}
`;

// FIXME: color: black and border-bottom-color: black are leaking in and overriding these styles
const Selected = styled.p`
  width: 100%;
  background: transparent;
  font-weight: 300;
  padding: 10px 0;
  color: white;
  line-height: 1.5;
  margin: 0;
`;

const Icon = styled(IconAtom)`
  position: absolute;
  font-size: 10px;
  top: 19px;
  right: 0;
`;

const Input = styled.select`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 58px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const ArrowIcon = styled.img`
  position: absolute;
  top: 18px;
  right: 24px;
  width: 32px;
  height: 32px;
  ${p => p.theme.max('md')`
    top: 16px;
    right: 22px;
    width: 26px;
    height: 26px;
  `}
`;

export default Select;
