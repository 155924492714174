import gql from 'graphql-tag';

import { loggedUserData, promocodeData } from './fragments';

export const CURRENT_USER = gql`
  query me {
    me {
      ...LoggedUserData
    }
  }
  ${loggedUserData}
`;

export const USER_PROMOCODES = gql`
  query UserPromocodes {
    userPromocodes {
      ...PromocodeData
    }
  }
  ${promocodeData}
`;