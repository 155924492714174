import React from 'react';

import { InAppNotificationsConsumer } from './inAppNotificationsContext';

export const withInAppNotifications = Component =>
  class Wrapper extends React.Component {
    render() {
      return (
        <InAppNotificationsConsumer>
          {notificationData => (
            <Component {...this.props} {...notificationData} />
          )}
        </InAppNotificationsConsumer>
      );
    }
  };
