import React from 'react';
import styled from 'styled-components';
// import LogoWhite from "core/assets/images/logo.svg";

import SATI_LOGO from "../../assets/images/sati_logo.png"

const Logo = () => {
  return (
    <LogoCont>
      {/* <LogoImage src={LogoWhite} /> */}
      <HeaderLogo src={SATI_LOGO} alt={'Sati Logo'} />
    </LogoCont>
  );
}

const LogoCont = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  ${p => p.theme.max('sm')`
    margin-bottom: 40px;
  `}
`;

// const LogoImage = styled.img`
//   width: 136px;
//   height: 136px;
// `;

const HeaderLogo = styled.img`
  display: block;
  width: 169px;
  ${(p) => p.theme.between("sm", "xl")`
      width: 11.3vw;
    `}
  ${(p) => p.theme.max("md")`
        width: 180px;
    `}
    ${p => p.theme.max('xs')`
      width: 43vw;
    `}
`;

export default Logo;
