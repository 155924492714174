import React from 'react';
import styled, { css } from 'styled-components';
import { hiddenMixin } from 'core/ui/helpers';

const ButtonAtom = styled.button`
  color: ${p => p.theme.colors.white};
  width: ${p => (p.fullWidth ? '100%' : 'auto')};
  padding: 15px 50px;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(50px);
  border-radius: 50px;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  border-width: 0;
  ${p => p.theme.max('sm')`
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    padding: 15px;
  `}
  cursor: pointer;
  transition: box-shadow ${p => p.theme.transitions.fast};
  &:hover {
    box-shadow: ${p => p.theme.shadows.level1};
  }
  &:disabled {
    color: ${p => p.theme.colors.grays.light};
  }
  ${hiddenMixin}
  ${p => p.isRed && css`background: rgba(232, 17, 17, 0.3);`};
  ${p => p.isLoading && css`color: transparent;`}
`;

const Button = ({ children, onClick, isLoading, disabled, isRed, fullWidth, ...rest }) => {
  return (
    <ButtonAtom
      isLoading={isLoading}
      onClick={disabled ? null : onClick}
      fullWidth={fullWidth}
      disabled={disabled}
      isRed={isRed}
      {...rest}
    >
      {children}
      {isLoading ? (
        <Loading><div></div><div></div><div></div><div></div></Loading>
      ) : null}
    </ButtonAtom>
  );
}

const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 10px;
  justify-content: center;
  left: 50%;
  transform: translate(-50%);
div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 3px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
div:nth-child(1) {
  animation-delay: -0.45s;
}
div:nth-child(2) {
  animation-delay: -0.3s;
}
div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

export default Button;
