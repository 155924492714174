import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from "@apollo/react-hooks";
import { withTranslation } from 'react-i18next';

import { Input, Text, Button } from 'core/ui/atoms';
import { loginWithPassword } from 'core/api/actions';
import { withInAppNotifications } from 'core/api/toast/withInAppNotifications';
import { loginWithToken } from '../../api/actions';
import { SIGN_IN_WITH_TOKEN } from '../../api/apollo/mutations';

const SignInForm = ({ t, showNotification }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [pending, setPending] = useState(false);
  const [isTokenLogin, setIsTokenLogin] = useState(false);

  const [signInWithToken] = useMutation(SIGN_IN_WITH_TOKEN);

  const handleSwitchChange = (value) => {
    setIsTokenLogin(value);
  };

  const onSignIn = async () => {
    if (pending) {
      return;
    }
    setPending(true);
    try {
      await loginWithPassword(email, password);
      setEmail('');
      setPassword('');
      setPending(false);
    } catch (e) {
      // console.log(e.message);
      if (e.message === 'GraphQL error: Incorrect user') {
        showNotification({
          title: t('Notifications.LoginError'),
          message: t('Notifications.WrongLogin'),
          type: 'ERROR',
        });
      } else if (e.message === 'GraphQL error: Incorrect password') {
        showNotification({
          title: t('Notifications.LoginError'),
          message: t('Notifications.WrongPassword'),
          type: 'ERROR',
        });
      }
      setPending(false);
    }
  };

  const onSignInWithToken = async () => {
    if (pending) {
      return;
    }
    setPending(true);
    try {
      const authResponse = await signInWithToken({
        variables: {
          token,
        },
      });
      const authToken = authResponse?.data?.signInWithToken?.token;
      if (authToken) {
        await loginWithToken(authToken);
      }
      setToken('');
      setPending(false);
    } catch (e) {
      // console.log(e.message);
      if (e.message === 'GraphQL error: AuthenticationError: Token authentication disabled') {
        showNotification({
          title: t('Notifications.LoginError'),
          message: t('Notifications.TokenDisabled'),
          type: 'ERROR',
        });
      } else if (e.message === 'GraphQL error: AuthenticationError: Incorrect token') {
        showNotification({
          title: t('Notifications.LoginError'),
          message: t('Notifications.WrongToken'),
          type: 'ERROR',
        });
      }
      setPending(false);
    }
  };

  return (
    <Container>
      <Title>{t('main.authorization')}</Title>
      <SwitchContainer>
        <SwitchLabel
          isActive={!isTokenLogin}
          onClick={() => handleSwitchChange(false)}
        >
          Вход по почте
        </SwitchLabel>
        <SwitchLabel
          isActive={isTokenLogin}
          onClick={() => handleSwitchChange(true)}
        >
          Вход по токену
        </SwitchLabel>
      </SwitchContainer>
      {isTokenLogin ? (
        <>
          <Label>Token</Label>
          <Input
            value={token}
            onChange={(event) => setToken(event.currentTarget.value)}
          />
          <ButtonCont>
            <Button
              onClick={onSignInWithToken}
              fullWidth
              isLoading={pending}
              disabled={!token}
            >
              {t('main.signIn')}
            </Button>
          </ButtonCont>
        </>
      ) : (
        <>
          <Label>E-mail</Label>
          <Input
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
          <Label>{t('main.password')}</Label>
          <Input
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            type='password'
          />
          <ButtonCont>
            <Button
              onClick={onSignIn}
              fullWidth
              isLoading={pending}
              disabled={!email || !password}
            >
              {t('main.signIn')}
            </Button>
          </ButtonCont>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 578px;
  margin-right: auto;
  margin-left: auto;
`;

const Title = styled.h2`
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  color: ${(p) => p.theme.colors.white};
`;

const Label = styled(Text)`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: ${(p) => p.theme.colors.white};
`;

const ButtonCont = styled.div`
  width: 100%;
  margin-top: 60px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const SwitchLabel = styled.label`
  margin: 0 0.5rem;
  font-size: 1rem;
  color: ${(p) => (p.isActive ? 'white' : p.theme.colors.grays.dark)};
  cursor: pointer;
`;

export default withInAppNotifications(withTranslation()(SignInForm));
