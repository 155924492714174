import React from 'react';
import styled from 'styled-components';
import history from 'core/api/history';
import { withTranslation } from 'react-i18next';

import { Button, Logo, Text } from 'core/ui/atoms';
import CheckImage from 'core/assets/images/check.svg';

const SuccessMessage = ({ data, t }) => {
  if (!data) {
    return null;
  }

  const goToHome = () => {
    history.push('/');
  }

  return (
    <div>
      <Logo />
      <ContentWrapper>
        <IconCont>
          <CheckIcon src={CheckImage} />
        </IconCont>
        <CheckText>{t('main.successPaymentMessage')}</CheckText>
        <Button onClick={goToHome}>{t('main.goToHomepage')}</Button>
      </ContentWrapper>
      {/* <div><h2>Success</h2></div>
      <p>Ваш заказ успешно оплачен</p>
      <p>Сумма {parseFloat(data.Amount) / 100} ₽</p>
      <p>{data?.MerchantName}</p>
      <p>{data?.CompanyName}</p>
      <p>{data?.MerchantEmail}</p>
      <p>Phone +7 {data?.PhonesReq}</p>
      <p>Заказ {data?.OrderId}</p> */}
    </div>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  ${p => p.theme.max('sm')`
    padding-top: 100px;
	`}
`;

const IconCont = styled.div`
  width: 250px;
  height: 250px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  ${p => p.theme.max('sm')`
    width: 180px;
    height: 180px;
	`}
`;

const CheckIcon = styled.img`
  width: 160px;
  height: 160px;
  ${p => p.theme.max('sm')`
    width: 120px;
    height: 120px;
	`}
`;

const CheckText = styled(Text)`
  font-weight: 450;
  font-size: 32px;
  line-height: 41px;
  color: ${p => p.theme.colors.white};
  ${p => p.theme.max('sm')`
    font-size: 22px;
    line-height: 28px;
	`}
`;

export default withTranslation()(SuccessMessage);
