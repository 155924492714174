import React from 'react';
import styled from 'styled-components';

import { Modal } from 'core/ui/atoms';

const PromocodeInfoModal = ({ isVisible, onClose }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      // fullScreen
    >
      <WhiteSpace>
        <p>Инструкция для применения промокода:</p>
        <p>Если у Вас на данный момент нет аккаунта, требуется совершить ряд простых действий:</p>
        <ol>
          <li>Установите приложение SATI. Ссылка на магазин: <a href="https://apps.apple.com/mk/app/id1513177968?mt=8" target={'__blank'}>https://apps.apple.com/mk/app/id1513177968?mt=8</a></li>
          <li>Пройдите простую процедуру регистрации.</li>
          <li>Перейдите на официальный сайт <a href="https://www.sati.app" target={'__blank'}>www.sati.app</a></li>
          <li>Авторизуйтесь, используя данные, которые были введены при регистрации в приложении.</li>
          <li>Введите в соответствующее поле «Промокод» полученный Вами ранее уникальный код.</li>
          <li>Поздравляем! Подписка активирована. Скорее переходите в приложение для прослушивания нового дня.</li>
        </ol>
        <p>Если у Вас уже есть аккаунт, ниже мы описали ряд простых шагов для активации Промокода:</p>
        <ol>
          <li>Перейдите на официальный сайт <a href="https://www.sati.app" target={'__blank'}>www.sati.app</a>.</li>
          <li>Авторизуйтесь, используя данные, которые были введены при регистрации в приложении.</li>
          <li>Введите в соответствующее поле «Промокод» полученный Вами ранее уникальный код.</li>
          <li>Поздравляем! Подписка активирована. Скорее переходите в приложение для прослушивания нового дня.</li>
        </ol>
        <p>Если у Вас возникли какие-либо вопросы, наша заботливая поддержка с радостью ответит на них в официальный социальных сетях приложения SATI, а также по почте <a href="mailto:support@sati.app">support@sati.app</a></p>
      </WhiteSpace>
    </Modal>
  );
};

const WhiteSpace = styled.div`
  white-space: normal;
`;

export default PromocodeInfoModal;
