import React from 'react';
import styled from 'styled-components';
import { withTranslation } from "react-i18next";

const CertificateInstruction = ({ t }) => {
  return (
    <Section>
      <Container>
        <ItemWrap>
          <Circle>1</Circle>
          <InfoWrap>
            <InfoTitle>Покупка</InfoTitle>
            <div>Подписка не оформляется — деньги будут списаны только один раз.</div>
          </InfoWrap>
        </ItemWrap>
        <ItemWrap>
          <Circle>2</Circle>
          <InfoWrap>
            <InfoTitle>Отправка</InfoTitle>
            <div>На ваш email придет красивый сертификат с кодом — его можно распечатать или просто переслать.</div>
          </InfoWrap>
        </ItemWrap>
        <ItemWrap>
          <Circle>3</Circle>
          <InfoWrap>
            <InfoTitle>Активация</InfoTitle>
            <div>Введя код, ваши друзья и близкие получат полный доступ к "Sati Plus". Срок активации не ограничен.</div>
          </InfoWrap>
        </ItemWrap>
      </Container>
    </Section>
  );
};

const Section = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Container = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  color: ${p => p.theme.colors.white};
  white-space: pre-wrap;
  padding-left: 60px;
  ${p => p.theme.max('md')`
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
  `}
`;

const ItemWrap = styled.div`
  width: ${100 / 3}%;
  display: flex;
  flex-direction: row;
  ${p => p.theme.max('md')`
    width: 100%;
    max-width: 480px;
    margin-bottom: 24px;
  `}
`;

const Circle = styled.div`
  width: 46px;
  height: 46px;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: rgb(0 0 0 / 30%) 6px 6px 13px;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  flex-shrink: 0;
`;

const InfoWrap = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

const InfoTitle = styled.div`
  font-size: 22px;
  line-height: 1.35;
  font-weight: 700;
  margin-bottom: 10px;
`;

export default withTranslation()(CertificateInstruction);
