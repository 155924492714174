import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const { Provider, Consumer } = React.createContext();

export class ColorThemeProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node)
    ])
  };

  state = {
    themeStyle: 'DARK'
  };

  async componentDidMount() {
    const themeStyle = localStorage.getItem('theme');
    if (themeStyle) {
      this.setState({ themeStyle });
    }
  }

  setTheme = (themeStyle) => {
    this.setState({ themeStyle });
    localStorage.setItem('theme', themeStyle);
  };

  toggleTheme = () => {
    this.setState((st) => {
      const themeStyle = st.themeStyle === 'DARK' ? 'LIGHT' : 'DARK';
      localStorage.setItem('theme', themeStyle);
      return { themeStyle };
    });
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          setTheme: this.setTheme,
          toggleTheme: this.toggleTheme,
          isDark: this.state.themeStyle === 'DARK'
        }}>
        {this.props.children}
      </Provider>
    );
  }
}

export const withTheme = (Component) =>
  class Wrapper extends React.Component {
    render() {
      return (
        <Consumer>
          {themeData => (
            <Component
              {...this.props}
              {...themeData}
            />
          )}
        </Consumer>
      );
    }
  };
