import {min, max, between, getCurrentBreakpoint} from '../ui/responsiveUtils';

const SHADOW_COLOR = 'rgba(30, 30, 30, 0.07)';

export const theme = {
  breakpoints: {
    xs: 449,
    sm: 767,
    md: 980,
    lg: 1100,
    container1245: 1100,
    xl: 1100,
    xxl: 1100
  },
  bg: {
    white: '#fff',
    red: 'linear-gradient(245.93deg,#E84B28 0.75%,#F92F60 87.48%)',
  },
  colors: {
    primary: '#5a8536',
    secondary: '#E84B28',
    black: 'rgba(9,10,30,0.85)',
    white: '#ffffff',
    grays: {
      darker: '#444444',
      dark: '#555555',
      regular: '#8E8E8E',
      light: '#D9D9D9',
      lighter: '#f2f2f2',
      disabled: '#AAAAAA'
    },
    danger: '#E81111',
    warning: '#ffa836',
    info: '#6654ff',
    success: '#46ff3e'
  },
  general: {
    gridSize: 12,
    gridGap: '12px',
    borderRadius: '5px',
    mobilePadding: '15px',
    borderWidth: '1px',
    headerHeight: '60px',
    menuShift: '52px',
    opacity: '1',
    modalBackdropColor: 'rgba(0, 0, 0, .8)',
    overlayColor: 'rgba(30, 30, 30, .85)',
    containerWidth: {
      md: '980px',
      lg: '1100px'
    }
  },
  transitions: {
    fast: '.2s ease',
    medium: '.5s ease',
    slow: '.8s ease'
  },
  zIndex: {
    header: 1000,
    headerFixed: 1030,
    modalBg: 1040,
    modal: 1050,
    popover: 1060,
    tooltip: 1070
  },
  shadows: {
    level1: `0 7px 12px ${SHADOW_COLOR}`,
    level2: `0 3px 40px ${SHADOW_COLOR}`,
    level3: `0 27px 45px ${SHADOW_COLOR}`
  },
  font: {
    size: {
      base: '16px',
      h1: '32px',
      h2: '28px',
      h3: '22px',
      h4: '17px',
      h5: '16px',
      h6: '14px',
      small: '12px',
      smaller: '10px'
    },
    lineHeight: {
      base: 1.57,
      reduced: 1.2
    },
    letterSpacing: {
      base: 'normal',
      accent: '0.2em'
    },
    family: {
      text: 'Inter',
      header: 'Inter',
      accent: 'Inter'
    }
  },
  min,
  max,
  between,
  getCurrentBreakpoint
};

export default theme;
