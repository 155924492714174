import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withInAppNotifications } from 'core/api/toast/withInAppNotifications';
import ToastContent from 'core/ui/components/ToastContent';
import { Flex } from 'core/ui/atoms';

class Toast extends PureComponent {
  static propTypes = {
    notifications: PropTypes.array
  };

  render() {
    const { notifications } = this.props;

    return (
      <ToastCont direction={'column'}>
        {notifications.map(n => <ToastContent key={n._id} notification={n} />)}
      </ToastCont>
    )
  }
}

const ToastCont = styled(Flex)`
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 1080;
  ${p => p.theme.max('sm')`
    right: 4px;
  `}
`;

export default withInAppNotifications(Toast);
