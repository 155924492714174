import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from "@apollo/react-hooks";

import { Input, Button } from 'core/ui/atoms';
import { CURRENT_USER } from 'core/api/apollo/queries';
import { ACTIVATE_PROMOCODE } from 'core/api/apollo/mutations';
import { withInAppNotifications } from 'core/api/toast/withInAppNotifications';
import { USER_PROMOCODES } from '../../api/apollo/queries';

const ERROR_MESSAGES = {
  NO_USER: 'Ошибка. Пользователь не найден.',
  NO_PROMOCODE: 'Неверный промокод',
  NOT_ACTIVE: 'Недействительный промокод',
  ACTIVATED: 'Промокод уже активирован',
  EXPIRED: 'Срок действия промокода истек',
  NOT_SELF: 'Нельзя применить этот промокод на аккаунт, с которого он был куплен',
  NOT_WITH_ACTIVE: 'У вас есть активная подписка. Вы не можете использовать этот промокод.',
  ONLY_CLEAN_SUB: 'Этот промокод можно использовать только на чистом аккаунте, на котором никогда не было подписок.',
  UNDEFINED: 'Произошла ошибка. Попробуйте еще раз.',
  STATUS: 'Промокод успешно активирован'
}

const Promocode = ({ togglePromocodeModal, showNotification }) => {
  const [promocode, setPromocode] = useState('');
  const [pending, setPending] = useState(false);

  const [activatePromocode] = useMutation(ACTIVATE_PROMOCODE);

  const onChangeInput = e => {
    setPromocode(e.target.value);
  }

  const activate = async () => {
    if (pending) {
      return;
    }
    setPending(true);
    try {
      const result = await activatePromocode({
        variables: {
          code: promocode
        },
        refetchQueries: [{ query: CURRENT_USER }, { query: USER_PROMOCODES }]
      });
      setPending(false);
      const status = result?.data?.activatePromocode?.status;
      // const promocodeData = result?.data?.activatePromocode?.promocode;
      if (ERROR_MESSAGES[status]) {
        showNotification({
          title: status === 'SUCCESS' ? 'Поздравляем!' : 'Ошибка',
          message: ERROR_MESSAGES[status],
          type: status === 'SUCCESS' ? 'INFO' : 'ERROR',
        });
      }
      // if (promocodeData && togglePromocodeModal) {
      //   togglePromocodeModal();
      // }
    } catch (e) {
      console.log(e);
      setPending(false);
      showNotification({
        title: 'Ошибка',
        message: 'Произошла ошибка. Попробуйте еще раз.',
        type: 'ERROR',
      });
    }
  }

  return (
    <Wrap>
      <PromocodeTitle>Активируйте промокод</PromocodeTitle>
      <InputWrap>
        <Input
          value={promocode}
          onChange={onChangeInput}
        />
        <Button
          isLoading={pending}
          disabled={pending || !promocode}
          onClick={activate}
        >
          Активировать
        </Button>
      </InputWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
`;

const InputWrap = styled.div`
  display: flex;
  button {
    margin-left: 12px;
  }
`;

const PromocodeTitle = styled.h2`
  padding-left: 32px;
  color: ${p => p.theme.colors.white};
  margin-bottom: 8px;
  font-size: 24px;
`;

export default withInAppNotifications(Promocode);
